/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Game } from '../models/Game';
import type { GameScore } from '../models/GameScore';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GameService {

    /**
     * Get Games
     * @param gameRound 
     * @returns Game Successful Response
     * @throws ApiError
     */
    public static getGamesGameGameRoundGet(
gameRound: number,
): CancelablePromise<Array<Game>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game/{game_round}',
            path: {
                'game_round': gameRound,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Score
     * @param gameId 
     * @param requestBody 
     * @returns Game Successful Response
     * @throws ApiError
     */
    public static setScoreGameScoreGameIdPost(
gameId: number,
requestBody: GameScore,
): CancelablePromise<Game> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/game/score/{game_id}',
            path: {
                'game_id': gameId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Score
     * @param gameId 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteScoreGameScoreGameIdDelete(
gameId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/game/score/{game_id}',
            path: {
                'game_id': gameId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Shuffle Finals
     * @returns Game Successful Response
     * @throws ApiError
     */
    public static shuffleFinalsGameShuffleFinalsPost(): CancelablePromise<Array<Game>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/game/shuffle/finals',
        });
    }

    /**
     * Recalculate Games
     * @returns any Successful Response
     * @throws ApiError
     */
    public static recalculateGamesGameRecalculatePost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/game/recalculate',
        });
    }

    /**
     * Delete Scores
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteScoresGameScoreAllDelete(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/game/score/all',
        });
    }

}
