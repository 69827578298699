/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Team } from '../models/Team';
import type { TeamCreate } from '../models/TeamCreate';
import type { TeamPatch } from '../models/TeamPatch';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeamService {

    /**
     * Delete Team
     * @param teamId 
     * @returns number Successful Response
     * @throws ApiError
     */
    public static deleteTeamTeamTeamIdDelete(
teamId: number,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/team/{team_id}',
            path: {
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Team
     * @param teamId 
     * @param requestBody 
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static patchTeamTeamTeamIdPatch(
teamId: number,
requestBody: TeamPatch,
): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/team/{team_id}',
            path: {
                'team_id': teamId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Team
     * @param requestBody 
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static createTeamTeamPost(
requestBody: TeamCreate,
): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/team/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
