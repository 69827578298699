/* eslint-disable react/no-array-index-key */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { getGamesKO } from './client';
import { Game } from './api';
import SingleGameView from './SingleGameView';

const KOTree: React.FC<{
  admin: boolean;
  active: boolean;
  refresh: number;
  triggerRefresh: () => void;
}> = ({ admin, active, refresh, triggerRefresh }) => {
  const [games, setGames] = useState<Game[]>([]);
  const [editGame, setEditGame] = useState<Game>();
  const [refreshing, setRefreshing] = useState(false);
  const [round, setRound] = useState(0);
  const localRefresh = useRef(refresh);
  const refreshGames = useCallback(() => {
    setRefreshing(true);
    getGamesKO().then((g) => {
      setGames(g);
      setRefreshing(false);
    });
  }, []);
  useEffect(() => {
    if (refresh === 0 || refresh > localRefresh.current) {
      refreshGames();
      localRefresh.current = refresh;
    }
    const cancel = setInterval(() => {
      refreshGames();
    }, 60000);
    return () => {
      clearInterval(cancel);
    };
  }, [refresh, refreshGames]);
  const onSetEdit = useCallback(
    (id: number) => () => {
      setEditGame(games.find((g) => g.id === id));
    },
    [games],
  );
  const onCloseEdit = useCallback(
    (r: boolean) => {
      setEditGame(undefined);
      if (r) {
        triggerRefresh();
      }
    },
    [triggerRefresh],
  );
  const viertl = useMemo(
    () =>
      games
        .filter((g) => g.gameRound === 5)
        .sort((a, b) => a.finalId - b.finalId),
    [games],
  );
  const halb = useMemo(
    () =>
      games
        .filter((g) => g.gameRound === 6)
        .sort((a, b) => a.finalId - b.finalId),
    [games],
  );
  const final = useMemo(
    () =>
      games
        .filter((g) => g.gameRound === 7)
        .sort((a, b) => a.finalId - b.finalId),
    [games],
  );
  const onRoundClicked = useCallback((r: number) => {
    setRound(r);
  }, []);
  const onClickA = useCallback(() => {
    onRoundClicked(0);
  }, [onRoundClicked]);
  const onClick1 = useCallback(() => {
    onRoundClicked(5);
  }, [onRoundClicked]);
  const onClick2 = useCallback(() => {
    onRoundClicked(6);
  }, [onRoundClicked]);
  const onClick3 = useCallback(() => {
    onRoundClicked(7);
  }, [onRoundClicked]);
  return (
    <div className={`gamesKO${active ? ' active' : ''}`}>
      {editGame && (
        <SingleGameView close={onCloseEdit} admin={admin} game={editGame} />
      )}
      <h2>
        K.O.-Phase
        {refreshing && (
          <div className="refreshing">
            <FontAwesomeIcon icon={faRefresh} />
          </div>
        )}
      </h2>
      <div className="row">
        <button type="button" onClick={onClickA}>
          Alle
        </button>
        <button type="button" onClick={onClick1}>
          Viertelfinale
        </button>
        <button type="button" onClick={onClick2}>
          Halbfinale
        </button>
        <button type="button" onClick={onClick3}>
          Finale
        </button>
      </div>
      <div className="tree">
        {(round === 0 || round === 5) && <span>Viertelfinale</span>}
        {(round === 0 || round === 5) &&
          (viertl.concat(Array(4).fill(0)) as (Game | number)[])
            .slice(0, 4)
            .map((g, index) => {
              if (typeof g === 'number')
                return (
                  <div className="viertl-game " key={`gamev-${index}`}>
                    <div className="teams">
                      <span>TBA</span>
                      <strong>Viertelfinale {index + 1}</strong>
                      <span>TBA</span>
                    </div>
                    <div className="score">
                      <span>0</span>
                      <span>0</span>
                    </div>
                  </div>
                );
              return (
                <div className="viertl-game " key={`game-${g.id}`}>
                  <div className="teams">
                    <span>
                      <strong>[{g.team1.number}]</strong>
                      {` ${g.team1.name}`}
                    </span>
                    <strong className="vs">vs.</strong>
                    <span>
                      <strong>[{g.team2.number}]</strong>
                      {` ${g.team2.name}`}
                    </span>
                  </div>
                  <div className="score">
                    <strong>{g.score1}</strong>
                    <span> - </span>
                    <strong>{g.score2}</strong>
                  </div>
                  {admin && (
                    <button type="button" onClick={onSetEdit.call(this, g.id)}>
                      <FontAwesomeIcon icon={faPencil} />
                    </button>
                  )}
                </div>
              );
            })}
        {(round === 0 || round === 6) && <span>Halbfinale</span>}
        {(round === 0 || round === 6) &&
          (halb.concat(Array(2).fill(0)) as (Game | number)[])
            .slice(0, 2)
            .map((g, index) => {
              if (typeof g === 'number')
                return (
                  <div className="viertl-game" key={`gameh-${index}`}>
                    <div className="teams">
                      <span>Gewinner Viertelfinale {index * 2 + 1}</span>
                      <strong>Halbfinale {index + 1}</strong>
                      <span>Gewinner Viertelfinale {index * 2 + 2}</span>
                    </div>
                    <div className="score">
                      <span>0</span>
                      <span>0</span>
                    </div>
                  </div>
                );
              return (
                <div className="viertl-game" key={`game-${g.id}`}>
                  <div className="teams">
                    <span>
                      <strong>[{g.team1.number}]</strong>
                      {` ${g.team1.name}`}
                    </span>
                    <strong className="vs">vs.</strong>
                    <span>
                      <strong>[{g.team2.number}]</strong>
                      {` ${g.team2.name}`}
                    </span>
                  </div>
                  <div className="score">
                    <strong>{g.score1}</strong>
                    <span> - </span>
                    <strong>{g.score2}</strong>
                  </div>
                  {admin && (
                    <button type="button" onClick={onSetEdit.call(this, g.id)}>
                      <FontAwesomeIcon icon={faPencil} />
                    </button>
                  )}
                </div>
              );
            })}
        {(round === 0 || round === 7) && <span>Finale</span>}
        {(round === 0 || round === 7) &&
          (final.concat(Array(2).fill(0)) as (Game | number)[])
            .slice(0, 2)
            .map((g, index) => {
              if (typeof g === 'number')
                return (
                  <div
                    className={`viertl-game ${index === 0 ? 'finale' : ''}`}
                    key={`gamef-${index}`}>
                    <div className="teams">
                      <span>
                        {index === 0
                          ? 'Gewinner Halbfinale 1'
                          : 'Verlierer Halbfinale 1'}
                      </span>
                      <strong>
                        {index === 0 ? 'Finale' : 'Spiel um Platz 3'}
                      </strong>
                      <span>
                        {index === 0
                          ? 'Gewinner Halbfinale 2'
                          : 'Verlierer Halbfinale 2'}
                      </span>
                    </div>
                    <div className="score">
                      <span>0</span>
                      <span>0</span>
                    </div>
                  </div>
                );
              return (
                <div
                  className={`viertl-game ${index === 0 ? 'finale' : ''}`}
                  key={`game-${g.id}`}>
                  <div className="teams">
                    <span>
                      <strong>[{g.team1.number}]</strong>
                      {` ${g.team1.name}`}
                    </span>
                    <strong className="vs">vs.</strong>
                    <span>
                      <strong>[{g.team2.number}]</strong>
                      {` ${g.team2.name}`}
                    </span>
                  </div>
                  <div className="score">
                    <strong>{g.score1}</strong>
                    <span> - </span>
                    <strong>{g.score2}</strong>
                  </div>
                  {admin && (
                    <button type="button" onClick={onSetEdit.call(this, g.id)}>
                      <FontAwesomeIcon icon={faPencil} />
                    </button>
                  )}
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default KOTree;

//  ss
