import {
  CancelablePromise,
  Game,
  GameScore,
  GameService,
  OpenAPI,
  ScoreService,
  TeamCreate,
  TeamPatch,
  TeamService,
} from './api';

OpenAPI.BASE = 'https://api.watten.kivi.bz.it';
export function getGames(): CancelablePromise<Game[]> {
  return new CancelablePromise<Game[]>((resolve, reject) => {
    GameService.getGamesGameGameRoundGet(1).then((games1) => {
      GameService.getGamesGameGameRoundGet(2).then((games2) => {
        GameService.getGamesGameGameRoundGet(3).then((games3) => {
          GameService.getGamesGameGameRoundGet(4).then((games4) => {
            resolve(games1.concat(games2, games3, games4));
          }, reject);
        }, reject);
      }, reject);
    }, reject);
  });
}

export function getGamesKO(): CancelablePromise<Game[]> {
  return new CancelablePromise<Game[]>((resolve, reject) => {
    GameService.getGamesGameGameRoundGet(5).then((games5) => {
      GameService.getGamesGameGameRoundGet(6).then((games6) => {
        GameService.getGamesGameGameRoundGet(7).then((games7) => {
          resolve(games5.concat(games6, games7));
        }, reject);
      }, reject);
    }, reject);
  });
}
export function getScore() {
  return ScoreService.getScoreScoreGet();
}

export function patchTeam(teamId: number, team: TeamPatch) {
  return TeamService.patchTeamTeamTeamIdPatch(teamId, team);
}

export function createTeam(team: TeamCreate) {
  return TeamService.createTeamTeamPost(team);
}

export function deleteTeam(team: number) {
  return TeamService.deleteTeamTeamTeamIdDelete(team);
}

export function patchGame(gameId: number, score: GameScore) {
  return GameService.setScoreGameScoreGameIdPost(gameId, score);
}

/**
 * delete a score
 * @param id
 */
export function resetGame(id: number) {
  return GameService.deleteScoreGameScoreGameIdDelete(id);
}
