import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsAltV,
  faPencil,
  faRefresh,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {
  createTeam,
  deleteTeam,
  getScore,
  patchTeam,
  resetGame,
} from './client';
import { Score } from './api';

const ScoreView: React.FC<{
  admin: boolean;
  active: boolean;
  refresh: number;
  triggerRefresh: () => void;
}> = ({ admin, active, refresh, triggerRefresh }) => {
  const [score, setScore] = useState<Score[]>([]);
  const [newTeam, setNewTeam] = useState('');
  const [patch, setPatch] = useState(-1);
  const [number, setNumber] = useState(0);
  const [wantDelete, setWantDelete] = useState(-1);
  const [refreshing, setRefreshing] = useState(false);
  const [sortBy, setSortBy] = useState<'placement' | 'number'>('placement');
  const localRefresh = useRef(refresh);
  const refreshScore = useCallback(() => {
    setRefreshing(true);
    getScore().then((s) => {
      setScore(s);
      setRefreshing(false);
    });
  }, []);
  const canEdit = useMemo(
    () => score.reduce((p, c) => p + c.points, 0) === 0,
    [score],
  );
  useEffect(() => {
    if (refresh === 0 || refresh > localRefresh.current) {
      refreshScore();
      localRefresh.current = refresh;
    }
    const cancel = setInterval(() => {
      refreshScore();
    }, 60000);
    return () => {
      clearInterval(cancel);
    };
  }, [refreshScore, refresh]);
  const onEnterTeamName = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => {
    setNewTeam(e.target.value);
  }, []);

  const onDeleteTeam = useCallback(
    (id: number) => () => {
      setWantDelete(id);
    },
    [],
  );
  const cancelDeleteTeam = useCallback(() => {
    setWantDelete(-1);
  }, []);
  const teamToDelete = useMemo(
    () => score.find((s) => s.id === wantDelete)?.team,
    [wantDelete, score],
  );
  const confirmDeleteTeam = useCallback(() => {
    if (teamToDelete)
      deleteTeam(teamToDelete.id).finally(() => {
        setWantDelete(-1);
        triggerRefresh();
      });
  }, [teamToDelete, triggerRefresh]);
  const onPatchTeam = useCallback(
    (id: number) => () => {
      setPatch(id);
    },
    [],
  );
  const teamToPatch = useMemo(() => {
    const team = score.find((s) => s.id === patch)?.team;
    if (team) {
      setNumber(team.number);
      setNewTeam(team.name);
      return team;
    }
    return undefined;
  }, [patch, score]);

  const onSaveNewTeam = useCallback(() => {
    if (patch === -1) {
      createTeam({ name: newTeam, number }).finally(() => {
        triggerRefresh();
        setNumber(number + 1);
        setNewTeam('');
      });
    } else if (teamToPatch) {
      patchTeam(teamToPatch.id, { name: newTeam, number }).finally(() => {
        setPatch(-1);
        setNewTeam('');
        triggerRefresh();
      });
    }
  }, [patch, teamToPatch, newTeam, number, triggerRefresh]);
  const onSetNumber = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => setNumber(parseInt(e.target.value, 10)),
    [],
  );
  return (
    <div className={`score${active ? ' active' : ''}`}>
      <h2>
        Punktestand{'  '}
        {refreshing && (
          <div className="refreshing">
            <FontAwesomeIcon icon={faRefresh} />
          </div>
        )}
      </h2>
      <table>
        <thead>
          <tr>
            <th
              onClick={() => {
                setSortBy('placement');
              }}>
              Platz{' '}
              {sortBy === 'placement' && (
                <FontAwesomeIcon icon={faArrowsAltV} />
              )}
            </th>
            <th
              onClick={() => {
                setSortBy('number');
              }}>
              Nr.{' '}
              {sortBy === 'number' && <FontAwesomeIcon icon={faArrowsAltV} />}
            </th>
            <th>Team</th>
            <th>Punkte</th>
            {admin && <th>Aktion</th>}
          </tr>
        </thead>
        <tbody>
          {score
            .sort((a, b) =>
              sortBy === 'number'
                ? a.team.number - b.team.number
                : a.placement - b.placement,
            )
            .map((s) => (
              <tr key={`score-${s.id}`} className={s.team.final ? 'bold' : ' '}>
                <td>{s.placement}</td>
                <td>{s.team.number}</td>
                <td>
                  <strong>{s.team.name}</strong>
                </td>
                <td>{s.points}</td>
                {admin && (
                  <td>
                    <button
                      type="button"
                      onClick={onPatchTeam.call(this, s.id)}>
                      <FontAwesomeIcon icon={faPencil} />
                    </button>
                    {canEdit && (
                      <button
                        type="button"
                        onClick={onDeleteTeam.call(this, s.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      {admin && (canEdit || (patch !== -1 && !!teamToPatch)) && (
        <div>
          <h3>
            {patch !== -1 && !!teamToPatch ? 'Team bearbeiten' : 'Neues Team'}
          </h3>
          <label htmlFor="number">
            Nr.:{' '}
            <input
              type="number"
              id="number"
              value={number}
              onChange={onSetNumber}
            />
          </label>
          <br />
          <label htmlFor="team-name">
            Name:{' '}
            <input
              type="text"
              id="team-name"
              onChange={onEnterTeamName}
              value={newTeam}
            />
          </label>
          <br />
          <button type="button" onClick={onSaveNewTeam}>
            Speichern
          </button>
          <span className="new-team-status" />
        </div>
      )}
      <div
        className={`modal ${
          wantDelete !== -1 && !!teamToDelete ? '' : 'hidden'
        }`}>
        <div className="content">
          <span>Sicher Team {teamToDelete?.name} löschen?</span>
          <button type="button" onClick={cancelDeleteTeam}>
            Nein
          </button>
          <button type="button" onClick={confirmDeleteTeam}>
            Ja
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScoreView;

// rel  od
